<template>
  <div class="modal__bg" @click="emitClose">
    <div>
      <svg
        @click="emitClose"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <div class="modal__container" @click.stop>
      <section class="contact">
        <div class="contact__information">
          <div class="contact__title-description">
            <h2 class="contact__subtitle">
              ¿Listo para impulsar <em>tus ventas?</em>
            </h2>
            <p class="contact__description">
              Déjanos tus datos y un asesor comercial se estará comunicando en
              un máximo de 24 horas.
            </p>
          </div>
          <div class="contact__contact">
            <form @submit.prevent="sendForm">
              <div class="contact__form-section">
                <InputComponent
                  :disabled="false"
                  :placeholder="'Nombre apellido'"
                  :type="'text'"
                  :required="true"
                  :bg_color="'var(--primary_white)'"
                  v-model="user_test.name"
                >
                </InputComponent>
              </div>
              <div class="contact__form-section">
                <InputComponent
                  :disabled="false"
                  :placeholder="'Empresa'"
                  :type="'text'"
                  :required="true"
                  :bg_color="'var(--primary_white)'"
                  v-model="user_test.business_name"
                >
                </InputComponent>
              </div>
              <div class="contact__form-section">
                <InputComponent
                  :disabled="false"
                  :placeholder="'RUC'"
                  :type="'text'"
                  :required="true"
                  :bg_color="'var(--primary_white)'"
                  v-model="user_test.tax_id"
                >
                </InputComponent>
              </div>
              <div class="contact__form-section">
                <InputComponent
                  :disabled="false"
                  :placeholder="'Celular'"
                  :type="'tel'"
                  :required="true"
                  :bg_color="'var(--primary_white)'"
                  v-model="user_test.phone"
                >
                </InputComponent>
              </div>
              <div class="contact__form-section">
                <InputComponent
                  :disabled="false"
                  :placeholder="'Correo'"
                  :type="'email'"
                  :required="true"
                  :bg_color="'var(--primary_white)'"
                  v-model="user_test.email"
                >
                </InputComponent>
              </div>
              <p class="contact__spam">
                *Al ingresar tus datos, aceptas recibir correos electrónico de
                marketing por parte de Quick eat!
              </p>
              <ButtonPrimary type="submit">Solicitar información</ButtonPrimary>
            </form>
          </div>
        </div>
        <div class="contact__image"></div>
      </section>
    </div>
  </div>
</template>

<script>
import InputComponent from "@/components/nuevo/inputs/InputComponent";
import User from "@/services/user-services";
import ButtonPrimary from "../nuevo/buttons/ButtonPrimary.vue";
export default {
  name: "PopUpLead",
  components: { InputComponent, ButtonPrimary },
  emits: ["lead_success", "close"],
  data() {
    return {
      user_test: {
        name: "",
        email: "",
        business_name: "",
        tax_id: "",
        phone: "",
        file: null,
      },
    };
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.$emit("close");
        // Or any other way you want to close your modal
      }
    });
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    async sendForm() {
      this.$gtag.event("conversion", {
        send_to: "AW-10975127378/UzlACIr8k9wDENLOrPEo",
      });
      console.log("info", this.user_test);

      if (this.user_test.name.length <= 3) {
        this.$toast.error("Debe ingresar su nombre completo");
        this.$gtag.event("formulario_incompleto", { fail: "nombre_persona" });
        return;
      }
      if (this.user_test.business_name.length < 3) {
        this.$toast.error("Debe ingresar el nombre comercial de su negocio");

        this.$gtag.event("formulario_incompleto", { fail: "nombre_comercial" });
        return;
      }
      if (this.user_test.tax_id.length !== 11) {
        this.$toast.error("Debe ingresar un RUC correcto");
        this.$gtag.event("formulario_incompleto", { fail: "ruc" });
        return;
      }
      if (this.user_test.phone.length < 9) {
        this.$toast.error("Debe ingresar un número telefónico válido");
        this.$gtag.event("formulario_incompleto", { fail: "phone" });
        return;
      }
      if (this.user_test.email.length <= 4) {
        this.$toast.error("Debe ingresar un correo válido");
        this.$gtag.event("formulario_incompleto", { fail: "error_correo" });
        return;
      }
      await this.sendMailServ();
    },
    async sendMailServ() {
      try {
        this.loading_send = true;
        this.user_test.file = new FormData();
        if (this.temp_file != null) {
          this.user_test.file.append("file", this.temp_file);
        }
        this.user_test.file.append("name", this.user_test.name);
        this.user_test.file.append(
          "business_name",
          this.user_test.business_name
        );
        this.user_test.file.append("tax_id", this.user_test.tax_id);
        this.user_test.file.append("phone", this.user_test.phone);
        this.user_test.file.append("email", this.user_test.email);

        let response = await User.submitForm(this.user_test.file);
        this.$gtag.event("formulario_enviado", { success: "enviado_servidor" });
        if (response.data.success) {
          this.$toast.success("Información registrada con éxito");

          this.loading_send = false;
          this.user_test.name = "";
          this.user_test.email = "";
          this.user_test.business_name = "";
          this.user_test.tax_id = "";
          this.user_test.phone = "";
          this.user_test.file = null;
        } else {
          this.loading_send = false;
          console.log("error", response.message);
          this.$bvToast.toast(response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      } catch (error) {
        console.log("el error es", error);
        this.$toast.error(error);
        this.loading_send = false;
      }
    },
  },
};
</script>

<style scoped>
.modal__bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000000cc;
  width: 100%;
  height: 100dvh;
  transition: all 0.3s ease;
  padding: 16px;
}

.modal__bg > div {
  display: flex;
  justify-content: flex-end;
  width: clamp(200px, 100%, 1000px);
}

.modal__bg svg {
  z-index: 5;
  color: var(--text-white);
  cursor: pointer;
  scale: 1;
  transition: all 0.3s linear;
  min-width: 24px;
  min-height: 24px;
  margin-bottom: 16px;
}

.modal__bg svg:hover {
  color: var(--primary-600);
  cursor: pointer;
  scale: 1.3;
}

.modal__container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--complementary-100);
  box-shadow: 0 0 8px rgba(41, 41, 41, 0.2);
  border-radius: 8px;
  width: 100%;
  max-width: clamp(200px, 100%, 1000px);
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
}

em {
  font-style: normal;
  color: var(--primary-600);
}

.contact__information {
  padding: 20px 10px;
}

.contact__description {
  margin: 10px 0;
}

.contact__information button {
  width: fit-content;
  margin-inline: auto;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact__spam {
  font-size: 0.875rem;
}

@media only screen and (min-width: 768px) {
  .modal__bg svg {
    left: 50%;
  }

  .contact {
    display: flex;
  }

  .contact__image {
    width: 70%;
    height: 100%;
    background-image: url("../../assets/img/formulario-image.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media only screen and (min-width: 1024px) {
  .contact__information {
    padding: 48px;
  }
}
</style>
