<template>
  <section class="about full-width content-grid">
    <section class="hero full-width">
      <div class="hero__container content-grid">
        <h1 class="hero__title">Sobre nosotros</h1>
      </div>
    </section>
    <section class="nosotros">
      <h2>¿Quiénes <em>somos?</em></h2>
      <div class="nosotros__information">
        <div class="nosotros__paragraphs">
          <p>
            Somos una startup peruana que digitaliza restaurantes a través de
            soluciones integrales, que incluyen la creación de tiendas online,
            facturación electrónica, gestión de cajas y de repartidores,
            inventario, costos, recetas, herramientas de marketing digital,
            sistemas de fidelización, entre otros.
          </p>
          <p>
            Todo lo desarrollamos de forma en que la información esté
            centralizada, sea fácil de entender y de utilizar. Con esto,
            propiciamos el aumento en las ventas del negocio y simplificamos sus
            labores operativas y comunicacionales.
          </p>
        </div>
        <img
            loading="lazy"
            alt="Quick eat!"
            srcset="
                  ../assets/img/sobre-nosotros-quick-eat.webp?width=100 100w,
                  ../assets/img/sobre-nosotros-quick-eat.webp?width=200 200w
              "
            src="../assets/img/sobre-nosotros-quick-eat.webp"
            sizes="(max-width: 500px) 100vw, 500px"
        />
      </div>
    </section>
    <section class="objetivos full-width content-grid">
      <section class="objetivos__list">
        <section class="objetivos__item">
          <h3>Misión</h3>
          <p>
            Desarrollar soluciones simples a desafíos complejos del rubro
            gastronómico, con una excelente experiencia de usuario y servicio
            postventa.
          </p>
        </section>
        <section class="objetivos__item">
          <h3>Visión</h3>
          <p>Ser el socio tecnológico de los mejores restaurantes</p>
        </section>
        <section class="objetivos__item">
          <h3>Pilares</h3>
          <p>Innovación constante, postventa ágil y experiencia de usuario.</p>
        </section>
      </section>
    </section>
    <section class="valores section">
      <div class="valores__container grid">
        <h2 class="valores__subtitle">Nuestros <em>valores</em></h2>
        <section class="valores__cards">
          <article class="valores__card" data-aos="fade-up">
            <img
                loading="lazy"
                role="presentation"
                alt=""
                srcset="
                  ../assets/img/integridad.webp?width=100 100w,
                  ../assets/img/integridad.webp?width=200 200w
              "
                src="../assets/img/integridad.webp"
                sizes="(max-width: 130px) 100vw, 130px"
            />
            <h3>Integridad</h3>
            <p>
              Cumplimos con los compromisos asumidos y actuamos en concordancia
              con los valores que nos definen.
            </p>
          </article>
          <article class="valores__card" data-aos="fade-up">
            <img
                loading="lazy"
                role="presentation"
                alt=""
                srcset="
                  ../assets/img/perseverancia.webp?width=100 100w,
                  ../assets/img/perseverancia.webp?width=200 200w
              "
                src="../assets/img/perseverancia.webp"
                sizes="(max-width: 130px) 100vw, 130px"
            />
            <h3>Perseverancia</h3>
            <p>
              Más de 6 años persiguiendo nuestra visión para brindar el mejor
              servicio posible.
            </p>
          </article>
          <article class="valores__card" data-aos="fade-up">
            <img
                loading="lazy"
                role="presentation"
                alt=""
                srcset="
                  ../assets/img/excelencia.webp?width=100 100w,
                  ../assets/img/excelencia.webp?width=200 200w
              "
                src="../assets/img/excelencia.webp"
                sizes="(max-width: 130px) 100vw, 130px"
            />
            <h3>Excelencia</h3>
            <p>
              Aspiramos a superar las expectativas de todos en el rubro
              (administrativos, operativos, gerencia, y comensales)
            </p>
          </article>
          <article class="valores__card" data-aos="fade-up">
            <img
                loading="lazy"
                role="presentation"
                alt=""
                srcset="
                  ../assets/img/transparencia.webp?width=100 100w,
                  ../assets/img/transparencia.webp?width=200 200w
              "
                src="../assets/img/transparencia.webp"
                sizes="(max-width: 130px) 100vw, 130px"
            />
            <h3>Transparencia</h3>
            <p>Comunicamos de manera clara, sincera y evidente.</p>
          </article>
          <article class="valores__card" data-aos="fade-up">
            <img
                loading="lazy"
                role="presentation"
                alt=""
                srcset="
                  ../assets/img/trabajo-en-equipo.webp?width=100 100w,
                  ../assets/img/trabajo-en-equipo.webp?width=200 200w
              "
                src="../assets/img/trabajo-en-equipo.webp"
                sizes="(max-width: 130px) 100vw, 130px"
            />
            <h3>Trabajo en equipo</h3>
            <p>
              Creemos en la grandeza de la co-creación interna y externa. La
              tolerancia, el respeto y la asertividad son nuestras prioridades.
            </p>
          </article>
        </section>
      </div>
    </section>
  </section>
  <WhatsappIconComponent :phone="'903035756'"></WhatsappIconComponent>
</template>

<script>
import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import { useHead } from "@unhead/vue";
import { useRoute } from "vue-router";
export default {
  name: "Nosotros",
  components: { WhatsappIconComponent },
  setup() {
    const route = useRoute();
    const canonicalUrl = `https://www.quickeat.pe${route.path}`;
    useHead({
      title: "Q.E - Sobre nosotros",
      link: [{ rel: "canonical", href: canonicalUrl }],
      meta: [
        // Metadatos Html
        {
          name: "description",
          content:
            "Tu tienda online alineada a tu identidad corporativa, sin complicaciones operativas y con una comunicación renovada. Quick eat!, tu aliado tecnológico.",
        },
        {
          name: "keywords",
          content:
            "Quickeat, Quick eat, Quick eat!, delivery, take out, recojo, protocolo, express, fast food",
        },
        { name: "author", content: "Quick eat" },
        { name: "copyright", content: "&copy; 2024 - Quick eat" },

        // Metadatos Google
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },

        // Metadatos Open Graph (https://ogp.me/)
        { name: "og:title", content: "Sobre Quick eat!" },
        { name: "og:description", content: "Startup peruana que digitaliza restaurantes a través de soluciones integrales. Aumenta las ventas del negocio y simplifica tus labores operativas y comunicacionales." },
        { name: "og:image", content: "../assets/img/open-graph/sobre-nosotros-quick-eat.png" },
        { name: "og:url", content: "https://quickeat.pe/nosotros" },
        { name: "og:type", content: "website" },
        { name: "og:site_name", content: "Quick eat" },

        // Metadatos Twiter Card (https://developer.x.com/en/docs/twitter-for-websites/cards/guides/getting-started)
/*        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: "Sobre Quick eat!" },
        { name: "twitter:description", content: "Startup peruana que digitaliza restaurantes a través de soluciones integrales. Aumenta las ventas del negocio y simplifica tus labores operativas y comunicacionales." },
        { name: "twitter:image", content: "../assets/img/open-graph/sobre-nosotros-quick-eat.png" },
        { property: "twitter:url", content: "https://quickeat.pe/nosotros" },
        { name: "twitter:site", content: "@nombredeusuariotwiter" },
        { name: "twitter:creator", content: "@nombredeusuariotwiter" },*/
      ],
    });
  },
};
</script>

<style scoped>
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  height: 100px;
  background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/Digital+media/landing_web_files/nosotros_a/hero-nosotros.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--text-white);
  text-align: center;
}

.nosotros {
  padding: 25px 0;
}

.nosotros h2 {
  text-align: center;
  margin-bottom: 20px;
}

em {
  font-style: normal;
  color: var(--primary-600);
}

.nosotros__information {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

p {
  line-height: 1.5;
}

.nosotros__information p:first-child {
  margin-bottom: 10px;
}

.nosotros__information img {
  width: clamp(200px, 100%, 350px);
  margin-inline: auto;
  aspect-ratio: 1 / 0.75;
}

.objetivos {
  background-color: var(--primary-50);
  padding: 25px 0;
}

.objetivos__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.objetivos__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  box-shadow: var(--box-shadow);
  background-color: var(--text-white);
  padding: 20px;
  border-radius: 16px;
  width: clamp(200px, 100%, 350px);
  height: 200px;
  text-align: center;
}

.objetivos__item h3 {
  font-size: 1.5rem;
}

.valores {
  padding: 25px 0;
}

.valores h2 {
  text-align: center;
  margin-bottom: 20px;
}

.valores__cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.valores__card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: clamp(200px, 100%, 325px);
  height: 300px;
  background-color: var(--primary-600);
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.5);
  border-radius: 1rem;
  padding: 20px;
  text-align: center;
  color: var(--text-white);
}

.valores__card  img{
  width: 130px;
  height: 130px;
  min-width: 130px;
  min-height: 130px;
}

.valores__card:nth-child(2) {
  background-color: var(--amarillo);
  color: var(--text-black);
}

.valores__card:nth-child(3) {
  background-color: #258ea6;
}

.valores__card:nth-child(4) {
  background-color: #212121;
}

@media only screen and (min-width: 768px) {
  .hero {
    background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/Digital+media/landing_web_files/nosotros_a/hero-nosotros-desktop.webp");
    height: 175px;
  }

  .nosotros__information {
    flex-direction: row;
  }
}

@media only screen and (min-width: 1024px) {
  .hero {
    height: 225px;
  }

  .nosotros,
  .objetivos,
  .valores {
    padding: 50px 0;
  }

  .nosotros h2 {
    margin-bottom: 40px;
  }

  .nosotros__information {
    align-items: center;
    gap: 60px;
  }

  .nosotros__information img {
    width: clamp(200px, 100%, 500px);
  }

  .objetivos__item {
    height: 250px;
  }

  .objetivos__item p {
    font-size: 1rem;
  }

  .valores h2 {
    margin-bottom: 40px;
  }

  .valores__cards {
    gap: 40px;
  }

  .valores__card {
    height: 375px;
    justify-content: flex-start;
    gap: 20px;
  }
}
</style>
