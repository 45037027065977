<template>
  <article class="quickeat-services-item">
    <div class="circle-container">
      <picture>
        <source
            type="image/webp"
            :srcset="srcset"
            media="(min-width: 1024px)"
            sizes="(max-width: 225px) 100vw, 225px"
        >
        <img
            loading="lazy"
            :alt="alt"
            :src="img"
            sizes="(max-width: 225px) 100vw, 225px"
        />
      </picture>
    </div>
    <div class="titles-paragraph">
      <h3>
        {{ title }} <strong>{{ strong }}</strong>
      </h3>
      <p>{{ paragraph }}</p>
    </div>
    <ButtonPrimary type="button" @click="openLink"> Ver más </ButtonPrimary>
  </article>
</template>

<script setup>
import { defineProps } from "vue";
import ButtonPrimary from "./buttons/ButtonPrimary.vue";

const props = defineProps([
  "srcset",
  "img",
  "alt",
  "title",
  "strong",
  "paragraph",
  "link",
]);

function openLink() {
  window.open(props.link, "_blank");
}
</script>

<style scoped>
.quickeat-services-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: clamp(280px, 100%, 500px);
  border-radius: 16px;
  padding: 16px;
  background-color: var(--text-white);
  text-align: center;
  box-shadow: 0 0 8px rgba(124, 124, 124, 0.2);
}

.quickeat-services-item strong {
  color: var(--primary-600);
}

.circle-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-inline: auto;
  min-height: 200px;
}

.titles-paragraph h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 6px;
}

.quickeat-services-item picture {
  position: relative;
  z-index: 5;
  width: clamp(200px, 100%, 225px);
  margin-inline: auto;
  align-self: center;
}

.quickeat-services-item:nth-child(4) picture {
  width: 225px;
}

.quickeat-services-item button {
  width: fit-content;
  margin-inline: auto;
}

@media only screen and (min-width: 768px) {
  .quickeat-services-item {
    width: clamp(280px, 100%, 340px);
  }

  .quickeat-services-item:first-child picture {
    width: clamp(200px, 100%, 265px);
  }

  .quickeat-services-item:nth-child(3) picture {
    width: clamp(200px, 100%, 300px);
  }

  .quickeat-services-item:nth-child(4) picture {
    width: clamp(200px, 100%, 200px);
  }
}

@media only screen and (min-width: 1024px) {
  .quickeat-services-item {
    width: clamp(280px, 100%, 450px);
    scale: 1;
    transition: scale 0.3s linear;
  }

  .quickeat-services-item:hover {
    scale: 1.03;
  }

  .quickeat-services-item picture {
    width: clamp(200px, 100%, 304px);
  }

  .quickeat-services-item:first-child picture {
    width: clamp(200px, 100%, 223px);
  }

  .quickeat-services-item:nth-child(2) picture {
    width: clamp(150px, 100%, 187px);
  }

  .quickeat-services-item:nth-child(3) picture {
    width: clamp(200px, 100%, 269px);
  }

  .quickeat-services-item:nth-child(4) picture {
    width: clamp(150px, 100%, 188px);
  }
}

@media only screen and (min-width: 1280px) {
  .quickeat-services-item {
    width: clamp(280px, 100%, 500px);
  }
}
</style>
