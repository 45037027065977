<template>
  <main class="full-width content-grid">
    <WhatsappIconComponent :phone="'903035756'"></WhatsappIconComponent>
    <Hero
      :hero_title="'GESTIÓN DE COCINA'"
      :hero_description="'Optimiza los tiempos en cocina y mejora la comunicación en tu equipo'"
      :hero_img="require('../assets/img/hero-gestion-de-cocina.webp')"
      :hero_img_add1="require('../assets/img/hero-cocina-add-1.webp')"
      :hero_img_add2="require('../assets/img/hero-cocina-add-2.webp')"
      :hero_img_add3="require('../assets/img/hero-cocina-add-3.webp')"
      :hero_img_add4="require('../assets/img/hero-cocina-add-4.webp')"
      :hero_img_add5="require('../assets/img/hero-cocina-add-5.webp')"
      :hero_alt="'GESTIÓN DE COCINA'"
      :styleImg="{ aspectRatio: '1 / .83' }"
      :styleImgAdd1="{ width: '70px', top: '15%', right: '-5%' }"
      :styleImgAdd2="{ width: '100px', top: '-10%', left: '-15%' }"
      :styleImgAdd3="{ width: '100px', bottom: '0', right: '-5%' }"
      :styleImgAdd4="{ width: '70px', bottom: '15%', left: '-15%' }"
      :styleImgAdd5="{ width: '70px', bottom: '-10%', left: '40%' }"
    />
    <ServicesAdvantage
      :animationData1="sensible"
      :title1="'Pantalla interactiva en cocina'"
      :strong1="'(KDS)'"
      :paragraph1="'Tendrás comandas digitales con toda la información necesaria para cocinar y ver tiempos de los platos en cocina en vivo.'"
      :animationData2="notificacion"
      :title2="'Comunicación efectiva entre'"
      :strong2="'cocina y meseros'"
      :paragraph2="'Tus meseros recibirán notificaciones en el momento exacto en que los platos terminen de cocinarse.'"
      :paragraph2_2="'Además, sabrán a qué mesa llevarlos mediante su app de meseros.'"
      :animationData3="list"
      :title3="'Diferencia los estados de preparación'"
      :strong3="'de las comandas'"
      :paragraph3="'Cada comanda debe ser correctamente preparada y en los tiempos necesarios: diferéncialas y evita errores. Deja de lado las pantallas con mucha información; ahora solo verás lo necesario e indispensable.'"
    />
    <aside class="ventas">
      <div class="ventas__container">
        <div class="ventas__image" data-aos="fade-in">
          <img
              loading="lazy"
              role="presentation"
              alt=""
              srcset="
                  ../assets/img/chef-men-3.webp?width=100 100w,
                  ../assets/img/chef-men-3.webp?width=200 200w,
                  ../assets/img/chef-men-3.webp?width=400 400w
              "
              src="../assets/img/chef-men-3.webp"
              sizes="(max-width: 275px) 100vw, 275px"
          />
        </div>
        <div class="ventas__information">
          <h2>Con <strong>Quick eat!</strong></h2>
          <p>
            Tendrás tecnología avanzada para la gestión de tu cocina, asegurando
            eficiencia y comunicación óptima entre meseros, chefs y cajas.
          </p>
        </div>
      </div>
    </aside>
  </main>
</template>

<script setup>
import Hero from "@/components/nuevo/Hero.vue";
import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import { useHead } from "@unhead/vue";
import { useRoute } from "vue-router";
import ServicesAdvantage from "@/components/nuevo/ServicesAdvantage.vue";
import sensible from "@/assets/animationViews/sensible.json";
import notificacion from "@/assets/animationViews/notificacion.json";
import list from "@/assets/animationViews/tarea.json";

const route = useRoute();
const canonicalUrl = `https://www.quickeat.pe${route.path}`;

useHead({
  title: "Q.E - Gestión de Cocina",
  link: [{ rel: "canonical", href: canonicalUrl }],
  meta: [
    // Metadatos Html
    {
      name: "description",
      content:
        "Optimiza la gestión de cocina en tu restaurante con Quick eat. Utiliza pantallas interactivas (KDS) para comandas digitales, mejora la comunicación entre cocina y meseros, y diferencía los estados de preparación de las comandas para una operación eficiente y sin errores.",
    },
    {
      name: "keywords",
      content:
        "Quickeat, Quick eat, Quick eat!, Gestión de cocina para restaurantes, Pantallas interactivas KDS, Comandas digitales para restaurantes, Comunicación cocina-meseros, Tecnología avanzada para restaurantes, Eficiencia en la gestión de cocina, Optimización de tiempos en cocina, Gestión de comandas en tiempo real, Integración cocina-meseros",
    },
    { name: "author", content: "Quick eat" },
    { name: "copyright", content: "&copy; 2024 - Quick eat" },

    // Metadatos Google
    { name: "robots", content: "index, follow" },
    { name: "googlebot", content: "index, follow" },
    { name: "language", content: "Spanish, English" },
    { name: "rating", content: "General" },

    // Metadatos Open Graph (https://ogp.me/)
    { name: "og:title", content: "Pantalla en cocina" },
    { name: "og:description", content: "Optimiza los tiempo en cocina a través de las pantallas interactivas y mejora la comunicación de tu equipo." },
    { name: "og:image", content: "../assets/img/open-graph/gestion-de-cocina.png" },
    { name: "og:url", content: "https://quickeat.pe/managmentcocina" },
    { name: "og:type", content: "website" },
    { name: "og:site_name", content: "Quick eat" },

    // Metadatos Twiter Card (https://developer.x.com/en/docs/twitter-for-websites/cards/guides/getting-started)
/*    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "Pantalla en cocina" },
    { name: "twitter:description", content: "Optimiza los tiempo en cocina a través de las pantallas interactivas y mejora la comunicación de tu equipo." },
    { name: "twitter:image", content: "../assets/img/open-graph/gestion-de-cocina.png" },
    { property: "twitter:url", content: "https://quickeat.pe/managmentcocina" },
    { name: "twitter:site", content: "@nombredeusuariotwiter" },
    { name: "twitter:creator", content: "@nombredeusuariotwiter" },*/
  ],
});
</script>

<style scoped>
.ventas {
  padding: 25px 0;
}

.ventas__image img {
  display: none;
}

.ventas__information {
  border-radius: 12px;
  padding: 16px;
  background-color: #fcb53e;
  color: var(--text-black);
  background-position: center;
  background-repeat: no-repeat;
}

.ventas__information h2 {
  color: var(--text-white);
  margin-bottom: 10px;
}

.ventas__information strong {
  color: var(--text-black);
}

.ventas__information p {
  color: var(--text-black);
  line-height: 1.5rem;
}

@media only screen and (min-width: 640px) {
  .ventas__container {
    position: relative;
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
    background-color: #fcb53e;
    background-size: cover;
    border-radius: 1.875rem;
    padding: 1.25rem 1rem;
    gap: 20px;
  }

  .ventas__image {
    position: absolute;
    bottom: 0;
    left: 3%;
  }

  .ventas__image img {
    display: block;
  }

  .ventas__information {
    grid-column: 2 / 3;
    background-color: transparent;
    background-image: none;
    padding: 1.5rem 0;
    text-align: left;
  }

  .ventas__image img {
    display: block;
    width: 175px;
  }
}

@media only screen and (min-width: 1024px) {
  .ventas {
    padding: 150px 0 150px 0;
  }

  .ventas__container {
    width: clamp(800px, 100%, 1000px);
    grid-template-columns: 275px 1fr;
    margin-inline: auto;
    padding-inline: 50px;
  }

  .ventas__information {
    padding: 2.5rem 0;
  }

  .ventas__image img {
    width: 275px;
  }

  .ventas__information h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .ventas__information p {
    font-size: 22px;
    line-height: 1.5;
  }
}
</style>
