<template>
  <main class="content-grid">
    <!-- <WhatsappIconComponent :phone="'903035756'"></WhatsappIconComponent> -->
    <section class="hero full-width content-grid">
      <div class="hero__container">
        <div class="hero__information">
          <div class="hero__container-h1">
            <h1>{{ displayedText }}</h1>
          </div>
          <p>Lo último en tecnología para el rubro gastronómico</p>
          <hr />
          <div class="hero__services">
            <p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34 26.5C34 25.1088 33.2379 23.893 32.1098 23.2458C31.8818 19.8324 29.0326 17.125 25.5625 17.125H22.4076L22.6012 15.125H23.625C24.1427 15.125 24.5625 14.7052 24.5625 14.1875V10.4375C24.5625 9.91975 24.1427 9.5 23.625 9.5H22.6351C22.1672 5.28713 18.5856 2 14.25 2C12.1512 2 10.2291 2.7705 8.75119 4.04319L7.5 2.375C7.32294 2.13894 7.04513 2 6.75 2H2.9375C2.41975 2 2 2.41975 2 2.9375C2 3.45525 2.41975 3.875 2.9375 3.875H6.28125L7.45563 5.44081C6.5985 6.60313 6.03231 7.99219 5.86488 9.5H4.875C4.35725 9.5 3.9375 9.91975 3.9375 10.4375V14.1875C3.9375 14.7052 4.35725 15.125 4.875 15.125H5.901L7.69206 33.1552C7.73975 33.6347 8.14313 34 8.625 34H27.4375C29.8719 34 31.8781 32.1344 32.1033 29.758C33.2349 29.1117 34 27.8939 34 26.5ZM30.1557 22.75H17.2193C17.6548 20.613 19.5487 19 21.8125 19H25.5625C27.8263 19 29.7201 20.613 30.1557 22.75ZM14.25 3.875C17.5503 3.875 20.2889 6.324 20.7453 9.5H12.8438L9.879 5.54706C11.0404 4.50787 12.5726 3.875 14.25 3.875ZM8.64525 7.027L10.5 9.5H7.75475C7.88331 8.60512 8.19275 7.76794 8.64525 7.027ZM5.8125 11.375H22.6875V13.25C21.9906 13.25 6.89669 13.25 5.8125 13.25V11.375ZM7.78525 15.125H20.7174L20.5112 17.2548C17.6541 17.8319 15.4639 20.2706 15.2652 23.2458C14.1371 23.893 13.375 25.1088 13.375 26.5C13.375 27.8939 14.1401 29.1117 15.2718 29.758C15.3554 30.6406 15.6843 31.4527 16.1901 32.125H9.474L7.78525 15.125ZM27.4375 32.125H19.9375C18.7153 32.125 17.6733 31.3411 17.2863 30.25H30.0888C29.7018 31.3411 28.6597 32.125 27.4375 32.125ZM30.25 28.375H17.125C16.0911 28.375 15.25 27.5339 15.25 26.5C15.25 25.4661 16.0911 24.625 17.125 24.625H30.25C31.2839 24.625 32.125 25.4661 32.125 26.5C32.125 27.5339 31.2839 28.375 30.25 28.375Z"
                  fill="white"
                />
              </svg>
              Fast food
            </p>
            <p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 13.5V4.5C10.5 4.075 10.6438 3.71875 10.9313 3.43125C11.2188 3.14375 11.575 3 12 3C12.425 3 12.7813 3.14375 13.0688 3.43125C13.3563 3.71875 13.5 4.075 13.5 4.5V13.5H15V4.5C15 4.075 15.1438 3.71875 15.4313 3.43125C15.7188 3.14375 16.075 3 16.5 3C16.925 3 17.2812 3.14375 17.5688 3.43125C17.8563 3.71875 18 4.075 18 4.5V13.5C18 14.9 17.5688 16.125 16.7063 17.175C15.8438 18.225 14.775 18.925 13.5 19.275V31.5C13.5 31.925 13.3563 32.2813 13.0688 32.5688C12.7813 32.8563 12.425 33 12 33C11.575 33 11.2188 32.8563 10.9313 32.5688C10.6438 32.2813 10.5 31.925 10.5 31.5V19.275C9.225 18.925 8.15625 18.225 7.29375 17.175C6.43125 16.125 6 14.9 6 13.5V4.5C6 4.075 6.14375 3.71875 6.43125 3.43125C6.71875 3.14375 7.075 3 7.5 3C7.925 3 8.28125 3.14375 8.56875 3.43125C8.85625 3.71875 9 4.075 9 4.5V13.5H10.5ZM25.5 21H22.5C22.075 21 21.7188 20.8563 21.4313 20.5688C21.1438 20.2812 21 19.925 21 19.5V10.5C21 8.75 21.6438 7.0625 22.9313 5.4375C24.2188 3.8125 25.55 3 26.925 3C27.375 3 27.75 3.175 28.05 3.525C28.35 3.875 28.5 4.2875 28.5 4.7625V31.5C28.5 31.925 28.3563 32.2813 28.0688 32.5688C27.7812 32.8563 27.425 33 27 33C26.575 33 26.2188 32.8563 25.9313 32.5688C25.6438 32.2813 25.5 31.925 25.5 31.5V21Z"
                  fill="#E8EAED"
                />
              </svg>
              Salón
            </p>
            <p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 31.5C7.075 31.5 6.71875 31.3563 6.43125 31.0688C6.14375 30.7813 6 30.425 6 30C6 29.575 6.14375 29.2188 6.43125 28.9313C6.71875 28.6438 7.075 28.5 7.5 28.5H28.5C28.925 28.5 29.2812 28.6438 29.5688 28.9313C29.8563 29.2188 30 29.575 30 30C30 30.425 29.8563 30.7813 29.5688 31.0688C29.2812 31.3563 28.925 31.5 28.5 31.5H7.5ZM12 25.5C10.35 25.5 8.9375 24.9125 7.7625 23.7375C6.5875 22.5625 6 21.15 6 19.5V7.5C6 6.675 6.29375 5.96875 6.88125 5.38125C7.46875 4.79375 8.175 4.5 9 4.5H30C30.825 4.5 31.5313 4.79375 32.1188 5.38125C32.7063 5.96875 33 6.675 33 7.5V12C33 12.825 32.7063 13.5312 32.1188 14.1188C31.5313 14.7063 30.825 15 30 15H27V19.5C27 21.15 26.4125 22.5625 25.2375 23.7375C24.0625 24.9125 22.65 25.5 21 25.5H12ZM12 22.5H21C21.825 22.5 22.5312 22.2063 23.1188 21.6188C23.7063 21.0312 24 20.325 24 19.5V7.5H9V19.5C9 20.325 9.29375 21.0312 9.88125 21.6188C10.4688 22.2063 11.175 22.5 12 22.5ZM27 12H30V7.5H27V12ZM12 22.5H9H24H12Z"
                  fill="#E8EAED"
                />
              </svg>
              Cafeterías
            </p>
            <p>¡Y más!</p>
          </div>
        </div>
        <div class="hero__image">
          <img
              loading="lazy"
              role="presentation"
              alt=""
              srcset="
                  ../assets/img/main-hero-chef.webp?width=200 200w,
                  ../assets/img/main-hero-chef.webp?width=400 400w,
                  ../assets/img/main-hero-chef.webp?width=600 600w,
                  ../assets/img/main-hero-chef.webp?width=600 800w
              "
              src="../assets/img/main-hero-chef.webp"
              sizes="(max-width: 500px) 100vw, 500px"
          />
          <img
              class="hero__image-absolute"
              loading="lazy"
              role="presentation"
              alt=""
              srcset="
                  ../assets/img/main-hero-data.webp?width=200 200w,
                  ../assets/img/main-hero-data.webp?width=400 400w,
                  ../assets/img/main-hero-data.webp?width=600 600w,
                  ../assets/img/main-hero-data.webp?width=800 800w
              "
              src="../assets/img/main-hero-data.webp"
              sizes="(max-width: 600px) 100vw, 600px"
          />
        </div>
      </div>
    </section>
    <section class="compromiso full-width content-grid">
      <div class="compromiso__container">
        <div class="compromiso__information">
          <h4>Comprometidos con tu pasión</h4>
          <h2>
            Valoramos cada esfuerzo que haces para
            <strong>hacer brillar tu restaurante</strong>
          </h2>
          <p>
            Sabemos que ser un empresario gastronómico requiere amor por la
            comida y un sacrificio constante, especialmente en los feriados.
          </p>
          <p>
            En Quick eat, queremos aligerar tu carga laboral mediante tecnología
            disruptiva y un diseño intuitivo y amigable.
          </p>
        </div>
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                  ../assets/img/compromiso-chef.webp?width=200 200w,
                  ../assets/img/compromiso-chef.webp?width=400 400w,
                  ../assets/img/compromiso-chef.webp?width=600 600w,
                  ../assets/img/compromiso-chef.webp?width=800 800w,
              "
            src="../assets/img/compromiso-chef.webp"
            sizes="(max-width: 450px) 100vw, 450px"
            data-aos="fade-right"
        />
      </div>
    </section>
    <section class="competidores full-width content-grid">
      <h2>Lo que todos te ofrecen</h2>
      <article class="competidores__services">
        <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="300"
        >
          <img
              loading="lazy"
              alt="Facturación electronica"
              srcset="
                  ../assets/img/facturacion-electronica.webp?width=100 100w,
                  ../assets/img/facturacion-electronica.webp?width=200 200w
              "
              src="../assets/img/facturacion-electronica.webp"
              sizes="(max-width: 64px) 100vw, 64px"
          />
          <h3>Facturación electrónica</h3>
        </article>
        <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <img
              loading="lazy"
              alt="Gestión de cajas"
              srcset="
                  ../assets/img/gestion-de-cajas.webp?width=100 100w,
                  ../assets/img/gestion-de-cajas.webp?width=200 200w
              "
              src="../assets/img/gestion-de-cajas.webp"
              sizes="(max-width: 64px) 100vw, 64px"
          />
          <h3>Gestión de cajas</h3>
        </article>
        <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="700"
        >
          <img
              loading="lazy"
              alt="Gestión de productos"
              srcset="
                  ../assets/img/gestion-de-productos.webp?width=100 100w,
                  ../assets/img/gestion-de-productos.webp?width=200 200w
              "
              src="../assets/img/gestion-de-productos.webp"
              sizes="(max-width: 64px) 100vw, 64px"
          />
          <h3>Gestión de productos</h3>
        </article>
        <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <img
              loading="lazy"
              alt="Control de stock"
              srcset="
                  ../assets/img/control-de-stock.webp?width=100 100w,
                  ../assets/img/control-de-stock.webp?width=200 200w
              "
              src="../assets/img/control-de-stock.webp"
              sizes="(max-width: 64px) 100vw, 64px"
          />
          <h3>Control de stock</h3>
        </article>
        <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="1100"
        >
          <img
              loading="lazy"
              alt="Control de insumos"
              srcset="
                  ../assets/img/control-de-insumos.webp?width=100 100w,
                  ../assets/img/control-de-insumos.webp?width=200 200w
              "
              src="../assets/img/control-de-insumos.webp"
              sizes="(max-width: 64px) 100vw, 64px"
          />
          <h3>Control de insumos</h3>
        </article>
        <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="1300"
        >
          <img
              loading="lazy"
              alt="Registro de recetas"
              srcset="
                  ../assets/img/gestion-de-productos.webp?width=100 100w,
                  ../assets/img/gestion-de-productos.webp?width=200 200w
              "
              src="../assets/img/gestion-de-productos.webp"
              sizes="(max-width: 64px) 100vw, 64px"
          />
          <h3>Registro de recetas</h3>
        </article>
        <article
          class="competidores__services-item"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <img
              loading="lazy"
              alt="Movimiento entre almacenes"
              srcset="
                  ../assets/img/movimiento-entre-almacenes.webp?width=100 100w,
                  ../assets/img/movimiento-entre-almacenes.webp?width=200 200w
              "
              src="../assets/img/movimiento-entre-almacenes.webp"
              sizes="(max-width: 64px) 100vw, 64px"
          />
          <h3>Movimiento entre almacenes</h3>
        </article>
      </article>
    </section>
    <section class="quickeat-services full-width content-grid">
      <div class="quickeat-services-title">
        <h4 class="subsubtitle">Lo que nos diferencia</h4>
        <h2>Además, te ofrecemos</h2>
      </div>
      <div class="quickeat-services-list">
        <QuickEatServices
          :srcset="require('../assets/img/servicios-de-e-commerce-apps-con-tu-marca.webp')"
          :img="require('../assets/img/servicios-de-e-commerce-apps-con-tu-marca-mobile.webp')"
          :alt="'E-commerce + apps con tu marca'"
          :title="'E-commerce + apps'"
          :strong="'con tu marca'"
          :paragraph="'Ten tu propia web y apps completamente personalizadas y muestra la escencia de tu marca.'"
          :link="'/ecommerce'"
        />
        <QuickEatServices
          :srcset="require('../assets/img/servicios-de-integracion-con-inteligencia-artificial.webp')"
          :img="require('../assets/img/servicios-de-integracion-con-inteligencia-artificial-mobile.webp')"
          :alt="'Integración con inteligencia artificial'"
          :title="'Integración con'"
          :strong="'inteligencia artificial'"
          :paragraph="'Permite que la IA te ayude a reducir operaciones, tome pedidos e interactúe en tiempo real con tus clientes.'"
          :link="'/integrationia'"
        />
        <QuickEatServices
          :srcset="require('../assets/img/servicios-de-control-de-costos-y-mermas.webp')"
          :img="require('../assets/img/servicios-de-control-de-costos-y-mermas-mobile.webp')"
          :alt="'Control de costos y mermas'"
          :title="'Control de'"
          :strong="'costos y mermas'"
          :paragraph="'Conoce y controla los food cost de tus platos, cada dato lo vale.'"
          :link="'/costomerma'"
        />
        <QuickEatServices
          :srcset="require('../assets/img/servicios-cuponera-virtual-y-omnicanalidad.webp')"
          :img="require('../assets/img/servicios-cuponera-virtual-y-omnicanalidad-mobile.webp')"
          :alt="'Cuponera virtual y omnicanalidad'"
          :title="'Cuponera'"
          :strong="'virtual y omnicanalidad'"
          :paragraph="'Recompensa a tus clientes e incentiva las próximas compras a través de cualquier canal.'"
          :link="'/cuponeravirtual'"
        />
        <QuickEatServices
          :srcset="require('../assets/img/servicios-gestion-de-mesas-y-de-personal.webp')"
          :img="require('../assets/img/servicios-gestion-de-mesas-y-de-personal-mobile.webp')"
          :alt="'Gestión de mesas y de personal'"
          :title="'Gestión de'"
          :strong="'mesas y de personal'"
          :paragraph="'Diseña el plano de tu salón y distinga las mesas vacías, ocupadas y facturadas para la gestión del restaurante.'"
          :link="'/gestionmesas'"
        />
        <QuickEatServices
          :srcset="require('../assets/img/servicios-de-campanas-eficientes-de-marketing.webp')"
          :img="require('../assets/img/servicios-de-campanas-eficientes-de-marketing-mobile.webp')"
          :alt="'Campañas eficientes de marketing'"
          :title="'Campañas'"
          :strong="'eficientes de marketing'"
          :paragraph="'Conoce y analiza el comportamiento de tus clientes y crea campañas para cada necesidad ¡Tus clientes lo amarán!'"
          :link="'/campaingsmarketing'"
        />
        <QuickEatServices
          :srcset="require('../assets/img/servicios-de-encuesta-de-satisfaccion.webp')"
          :img="require('../assets/img/servicios-de-encuesta-de-satisfaccion-mobile.webp')"
          :alt="'Encuesta de satisfacción'"
          :title="'Encuesta de'"
          :strong="'satisfacción'"
          :paragraph="'Conoce la opinión de tus clientes en cada compra, sus puntos de dolor y sus preferencias.'"
          :link="'/encuestasatisfaccion'"
        />
        <QuickEatServices
          :srcset="require('../assets/img/servicios-de-pantalla-de-cocina.webp')"
          :img="require('../assets/img/servicios-de-pantalla-de-cocina.webp')"
          :alt="'Pantalla en cocina'"
          :title="'Pantalla en'"
          :strong="'cocina'"
          :paragraph="'Despídete de las comandas físicas y mejora tus tiempos de preparación.'"
          :link="'/managmentcocina'"
        />
      </div>
    </section>
    <section class="integration full-width content-grid">
      <div class="integration__titles">
        <h2>Integración total</h2>
        <p>
          Armamos el rompecabezas por ti, para que tomes las mejores decisiones
          gerenciales
        </p>
      </div>
      <article class="integration__list">
        <article data-aos="fade" data-aos-delay="1200">
          <h4>Facturación y cajas</h4>
          <img
              class="img"
              loading="lazy"
              alt="Integración de facturación y cajas"
              srcset="
                  ../assets/img/integracion-de-facturacion-y-cajas.webp?width=100 100w,
                  ../assets/img/integracion-de-facturacion-y-cajas.webp?width=200 200w
              "
              src="../assets/img/integracion-de-facturacion-y-cajas.webp"
              sizes="(max-width: 68px) 100vw, 68px"
          />
        </article>
        <article data-aos="fade" data-aos-delay="300">
          <h4>Canales digitales y físicos</h4>
          <img
              class="img"
              loading="lazy"
              alt="Integración de canales digitales y físicos"
              srcset="
                  ../assets/img/integracion-de-canales-digitales-y-fisicos.webp?width=100 100w,
                  ../assets/img/integracion-de-canales-digitales-y-fisicos.webp?width=200 200w
              "
              src="../assets/img/integracion-de-canales-digitales-y-fisicos.webp"
              sizes="(max-width: 68px) 100vw, 68px"
          />
        </article>
        <article data-aos="fade" data-aos-delay="600">
          <h4>Recetas, costos y almacenes</h4>
          <img
              class="img"
              loading="lazy"
              alt="Integración de recetas, costos y almacenes"
              srcset="
                  ../assets/img/integracion-de-recetas-costos-y-almacenes.webp?width=100 100w,
                  ../assets/img/integracion-de-recetas-costos-y-almacenes.webp?width=200 200w
              "
              src="../assets/img/integracion-de-recetas-costos-y-almacenes.webp"
              sizes="(max-width: 68px) 100vw, 68px"
          />
        </article>
        <article data-aos="fade" data-aos-delay="1500">
          <h4>Enviós</h4>
          <img
              class="img"
              loading="lazy"
              alt="Integración de enviós"
              srcset="
                  ../assets/img/integracion-de-envios.webp?width=100 100w,
                  ../assets/img/integracion-de-envios.webp?width=200 200w
              "
              src="../assets/img/integracion-de-envios.webp"
              sizes="(max-width: 68px) 100vw, 68px"
          />
        </article>
        <article data-aos="fade" data-aos-delay="1800">
          <h4>Fidelización y marketing digital</h4>
          <img
              class="img"
              loading="lazy"
              alt="Integración de fidelización y marketing digital"
              srcset="
                  ../assets/img/integracion-de-fidelizacion-y-marketing-digital.webp?width=100 100w,
                  ../assets/img/integracion-de-fidelizacion-y-marketing-digital.webp?width=200 200w
              "
              src="../assets/img/integracion-de-fidelizacion-y-marketing-digital.webp"
              sizes="(max-width: 68px) 100vw, 68px"
          />
        </article>
        <article data-aos="fade" data-aos-delay="900">
          <h4>Cocina</h4>
          <img
              class="img"
              loading="lazy"
              alt="Integración de cocina"
              srcset="
                  ../assets/img/integracion-de-cocina.webp?width=100 100w,
                  ../assets/img/integracion-de-cocina.webp?width=200 200w
              "
              src="../assets/img/integracion-de-cocina.webp"
              sizes="(max-width: 68px) 100vw, 68px"
          />
        </article>
      </article>
    </section>
    <section class="toknow full-width content-grid">
      <h2>Conócenos y vive la experiencia <strong>Quick eat!</strong></h2>
      <div class="toknow__inf-video">
        <div class="toknow__information">
          <div>
            <p>
              Pedidos fáciles, entregas eficientes, fidelización, y gestión del
              restaurante sin complicaciones.
            </p>
            <ButtonPrimary type="button">Contáctanos</ButtonPrimary>
          </div>
        </div>
        <div class="toknow__video">
          <lite-youtube videoid="P0Z1NKRfPEI" class="frame__video"></lite-youtube>
          <ButtonPrimary type="button">Contáctanos</ButtonPrimary>
        </div>
      </div>
    </section>
    <section class="services full-width content-grid">
      <h2>Servicio <b>100% garantizado</b></h2>
      <div class="services__information">
        <img
            data-aos="fade-right"
            loading="lazy"
            alt="Sistema Quick Eat!"
            srcset="
                  ../assets/img/sistema-quick-eat.webp?width=200 200w,
                  ../assets/img/sistema-quick-eat.webp?width=400 400w,
                  ../assets/img/sistema-quick-eat.webp?width=600 600w,
                  ../assets/img/sistema-quick-eat.webp?width=600 800w
              "
            src="../assets/img/sistema-quick-eat.webp"
            sizes="(max-width: 600px) 100vw, 600px"
        />
        <div>
          <p class="services__description">
            Nuestro compromiso es con la transparencia y hacer nuestro mayor
            esfuerzo para que pagues lo justo.
          </p>
          <div class="services__toggle-container">
            <div class="services__toggle-item">
              <div class="services__toggle" @click="toggleClick('toggle1')">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 36 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3034_1302)">
                    <g clip-path="url(#clip1_3034_1302)">
                      <path
                        d="M24.885 11.87L15 21.755L9.615 16.385L7.5 18.5L15 26L27 14L24.885 11.87ZM18 3.5C9.72 3.5 3 10.22 3 18.5C3 26.78 9.72 33.5 18 33.5C26.28 33.5 33 26.78 33 18.5C33 10.22 26.28 3.5 18 3.5ZM18 30.5C11.37 30.5 6 25.13 6 18.5C6 11.87 11.37 6.5 18 6.5C24.63 6.5 30 11.87 30 18.5C30 25.13 24.63 30.5 18 30.5Z"
                        fill="#F15A24"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_3034_1302">
                      <rect
                        width="36"
                        height="36"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                    <clipPath id="clip1_3034_1302">
                      <rect
                        width="36"
                        height="36"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <p>Garantizamos nuestro servicios</p>
                <svg
                  :class="selectedToggle === 'toggle1' ? 'tooggle-rotate' : ''"
                  width="24"
                  height="24"
                  viewBox="0 0 36 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 22.9251C17.8 22.9251 17.6125 22.8938 17.4375 22.8313C17.2625 22.7688 17.1 22.6626 16.95 22.5126L10.05 15.6126C9.77501 15.3376 9.63751 14.9876 9.63751 14.5626C9.63751 14.1376 9.77501 13.7876 10.05 13.5126C10.325 13.2376 10.675 13.1001 11.1 13.1001C11.525 13.1001 11.875 13.2376 12.15 13.5126L18 19.3626L23.85 13.5126C24.125 13.2376 24.475 13.1001 24.9 13.1001C25.325 13.1001 25.675 13.2376 25.95 13.5126C26.225 13.7876 26.3625 14.1376 26.3625 14.5626C26.3625 14.9876 26.225 15.3376 25.95 15.6126L19.05 22.5126C18.9 22.6626 18.7375 22.7688 18.5625 22.8313C18.3875 22.8938 18.2 22.9251 18 22.9251Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <p :class="selectedToggle === 'toggle1' ? 'toggle-click' : ''">
                Contamos con más de 5 años de funcionamiento ininterrumpido.
                Además, brindamos soporte inmediato.
              </p>
            </div>
            <div class="services__toggle-item">
              <div class="services__toggle" @click="toggleClick('toggle2')">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 36 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3034_1302)">
                    <g clip-path="url(#clip1_3034_1302)">
                      <path
                        d="M24.885 11.87L15 21.755L9.615 16.385L7.5 18.5L15 26L27 14L24.885 11.87ZM18 3.5C9.72 3.5 3 10.22 3 18.5C3 26.78 9.72 33.5 18 33.5C26.28 33.5 33 26.78 33 18.5C33 10.22 26.28 3.5 18 3.5ZM18 30.5C11.37 30.5 6 25.13 6 18.5C6 11.87 11.37 6.5 18 6.5C24.63 6.5 30 11.87 30 18.5C30 25.13 24.63 30.5 18 30.5Z"
                        fill="#F15A24"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_3034_1302">
                      <rect
                        width="36"
                        height="36"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                    <clipPath id="clip1_3034_1302">
                      <rect
                        width="36"
                        height="36"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <p>No vendemos un producto terminado</p>
                <svg
                  :class="selectedToggle === 'toggle2' ? 'tooggle-rotate' : ''"
                  width="24"
                  height="24"
                  viewBox="0 0 36 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 22.9251C17.8 22.9251 17.6125 22.8938 17.4375 22.8313C17.2625 22.7688 17.1 22.6626 16.95 22.5126L10.05 15.6126C9.77501 15.3376 9.63751 14.9876 9.63751 14.5626C9.63751 14.1376 9.77501 13.7876 10.05 13.5126C10.325 13.2376 10.675 13.1001 11.1 13.1001C11.525 13.1001 11.875 13.2376 12.15 13.5126L18 19.3626L23.85 13.5126C24.125 13.2376 24.475 13.1001 24.9 13.1001C25.325 13.1001 25.675 13.2376 25.95 13.5126C26.225 13.7876 26.3625 14.1376 26.3625 14.5626C26.3625 14.9876 26.225 15.3376 25.95 15.6126L19.05 22.5126C18.9 22.6626 18.7375 22.7688 18.5625 22.8313C18.3875 22.8938 18.2 22.9251 18 22.9251Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <p :class="selectedToggle === 'toggle2' ? 'toggle-click' : ''">
                Nos encontramos en innovación constante, para mejorar orientados
                a tus necesidades.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="customers full-width content-grid">
      <h2>Nuestros <b>aliados</b></h2>
      <div>
        <div class="customers-list">
          <a href="https://barrilitoexpress.com/" target="_blank">
            <img
                loading="lazy"
                alt="Logo de Barrilito expres"
                srcset="
                  ../assets/img/logo-barrilito-express.webp?width=100 100w,
                  ../assets/img/logo-barrilito-express.webp?width=200 200w,
              "
                src="../assets/img/logo-barrilito-express.webp"
                sizes="(max-width: 170px) 100vw, 170px"
            />
          </a>
          <a href="https://200millas.pe/" target="_blank">
            <img
                loading="lazy"
                alt="Logo de 200 millas"
                srcset="
                  ../assets/img/logo-200-millas.webp?width=100 100w,
                  ../assets/img/logo-200-millas.webp?width=200 200w,
              "
                src="../assets/img/logo-200-millas.webp"
                sizes="(max-width: 170px) 100vw, 170px"
            />
          </a>
<!--          <a href="https://www.amkha.pe/" target="_blank">
            <img src="../assets/img/logo-amkha.webp" alt="Logo de Amkha" loading="lazy" />
          </a>-->
          <a href="https://tostadocafe.pe/" target="_blank">
            <img
                loading="lazy"
                alt="Logo de Tostado café"
                srcset="
                  ../assets/img/logo-tostado-cafe.webp?width=100 100w,
                  ../assets/img/logo-tostado-cafe.webp?width=200 200w,
              "
                src="../assets/img/logo-tostado-cafe.webp"
                sizes="(max-width: 170px) 100vw, 170px"
            />
          </a>
          <a href="https://www.caporal.pe/" target="_blank">
            <img
                loading="lazy"
                alt="Logo de Caporal"
                srcset="
                  ../assets/img/logo-caporal.webp?width=100 100w,
                  ../assets/img/logo-caporal.webp?width=200 200w,
              "
                src="../assets/img/logo-caporal.webp"
                sizes="(max-width: 170px) 100vw, 170px"
            />
          </a>
          <a href="https://www.dondewalter.pe/" target="_blank">
            <img
                loading="lazy"
                alt="Logo de Donde Walter"
                srcset="
                  ../assets/img/logo-donde-walter.webp?width=100 100w,
                  ../assets/img/logo-donde-walter.webp?width=200 200w,
              "
                src="../assets/img/logo-donde-walter.webp"
                sizes="(max-width: 170px) 100vw, 170px"
            />
          </a>
          <a href="https://larevancha.pe/" target="_blank">
            <img
                loading="lazy"
                alt="Logo de La Revancha"
                srcset="
                  ../assets/img/logo-la-revancha.webp?width=100 100w,
                  ../assets/img/logo-la-revancha.webp?width=200 200w,
              "
                src="../assets/img/logo-la-revancha.webp"
                sizes="(max-width: 170px) 100vw, 170px"
            />
          </a>
          <a href="https://www.adiccionburger.com/" target="_blank">
            <img
                loading="lazy"
                alt="Logo de Adicción Burguer"
                srcset="
                  ../assets/img/logo-adiccion-burguer.webp?width=100 100w,
                  ../assets/img/logo-adiccion-burguer.webp?width=200 200w,
              "
                src="../assets/img/logo-adiccion-burguer.webp"
                sizes="(max-width: 170px) 100vw, 170px"
            />
          </a>
          <a href="https://www.chifamanmanchi.com/" target="_blank">
            <img
                loading="lazy"
                alt="Logo de Man Man Chi"
                srcset="
                  ../assets/img/logo-man-man-chi.webp?width=100 100w,
                  ../assets/img/logo-man-man-chi.webp?width=200 200w,
              "
                src="../assets/img/logo-man-man-chi.webp"
                sizes="(max-width: 170px) 100vw, 170px"
            />
          </a>
        </div>
      </div>
    </section>
    <section class="resumen full-width content-grid">
      <div>
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                  ../assets/img/resumen.webp?width=200 200w,
                  ../assets/img/resumen.webp?width=400 400w,
                  ../assets/img/resumen.webp?width=600 600w,
                  ../assets/img/resumen.webp?width=600 800w
              "
            src="../assets/img/resumen.webp"
            sizes="(max-width: 225px) 100vw, 225px"
        />
        <div class="resumen__information">
          <h2>En Quick eat! sabemos lo que necesitas</h2>
          <p>
            6 años de experiencia, millones de soles procesados y miles de
            clientes nos respaldan.
          </p>
        </div>
        <img
            class="resumen-vector vector-1"
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                  ../assets/img/resumen-vectores.webp?width=200 200w,
                  ../assets/img/resumen-vectores.webp?width=400 400w,
                  ../assets/img/resumen-vectores.webp?width=600 600w,
                  ../assets/img/resumen-vectores.webp?width=600 800w
              "
            src="../assets/img/resumen-vectores.webp"
            sizes="(max-width: 200px) 100vw, 200px"
        />
        <img
            class="resumen-vector vector-2"
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                  ../assets/img/resumen-vectores.webp?width=200 200w,
                  ../assets/img/resumen-vectores.webp?width=400 400w,
                  ../assets/img/resumen-vectores.webp?width=600 600w,
                  ../assets/img/resumen-vectores.webp?width=600 800w
              "
            src="../assets/img/resumen-vectores.webp"
            sizes="(max-width: 200px) 100vw, 200px"
        />
      </div>
    </section>
    <section class="testimonios full-width content-grid">
      <h2>Testimonios reales</h2>
      <Carousel_reviews :info_carousel="info_carousel"> </Carousel_reviews>
    </section>
    <section class="contact full-width" ref="contact" id="contact">
      <div class="contact__container">
        <div class="contact__information">
          <div class="contact__titles">
            <h2>¿Listo para impulsar <b>tus ventas?</b></h2>
            <p>
              Déjanos tus datos y un asesor comercial se estará comunicando en
              un máximo de 24 horas.
            </p>
          </div>
          <form @submit.prevent="sendForm" data-aos="fade-up">
            <div class="contact__form-section">
              <InputComponent
                :disabled="false"
                :placeholder="'Nombre apellido'"
                :type="'text'"
                :required="true"
                :bg_color="'var(--primary_white)'"
                v-model="user_test.name"
              >
              </InputComponent>
            </div>
            <div class="contact__form-section">
              <InputComponent
                :disabled="false"
                :placeholder="'Empresa'"
                :type="'text'"
                :required="true"
                :bg_color="'var(--primary_white)'"
                v-model="user_test.business_name"
              >
              </InputComponent>
            </div>
            <div class="contact__form-section">
              <InputComponent
                :disabled="false"
                :placeholder="'RUC'"
                :type="'text'"
                :required="true"
                :bg_color="'var(--primary_white)'"
                v-model="user_test.tax_id"
              >
              </InputComponent>
            </div>
            <div class="contact__form-section">
              <InputComponent
                :disabled="false"
                :placeholder="'Celular'"
                :type="'tel'"
                :required="true"
                :bg_color="'var(--primary_white)'"
                v-model="user_test.phone"
              >
              </InputComponent>
            </div>
            <div class="contact__form-section">
              <InputComponent
                :disabled="false"
                :placeholder="'Correo'"
                :type="'email'"
                :required="true"
                :bg_color="'var(--primary_white)'"
                v-model="user_test.email"
              >
              </InputComponent>
            </div>
            <p class="contact__spam">
              *Al ingresar tus datos, aceptas recibir correos electrónico de
              marketing por parte de Quick eat!
            </p>
            <ButtonPrimary>{{ send_text }}</ButtonPrimary>
          </form>
        </div>
        <div class="contact__image"></div>
      </div>
    </section>
  </main>
</template>

<script>
import User from "@/services/user-services";
// import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import Carousel_reviews from "@/components/Carousel_reviews";
import ButtonPrimary from "@/components/nuevo/buttons/ButtonPrimary.vue";
import InputComponent from "@/components/nuevo/inputs/InputComponent.vue";
import { useHead } from "@unhead/vue";
import { useRoute } from "vue-router";
import QuickEatServices from "@/components/nuevo/QuickEatServices.vue";
// import * as imageConversion from "image-conversion";

export default {
  name: "Home",
  components: {
    InputComponent,
    ButtonPrimary,
    Carousel_reviews,
    QuickEatServices,
    // WhatsappIconComponent,
  },
  data() {
    return {
      selectedToggle: null,
      phrases: [
        "SOLUCIÓN TODO EN UNO PARA RESTAURANTES",
        "NOS ADAPTAMOS A LAS NECESIDADES DE TU NEGOCIO",
        "NUESTRA MISIÓN ES TU ÉXITO",
      ],
      currentPhraseIndex: 0,
      displayedText: "",
      index: 0,
      isDeleting: false,

      current_banner:
        "https://quickeat.s3.us-west-2.amazonaws.com/Digital+media/landing_web_files/home_a/home_banner_1.webp",
      bannerIntervalId: null,
      user_test: {
        name: "",
        email: "",
        business_name: "",
        tax_id: "",
        phone: "",
        file: null,
      },
      loading_send: false,
      temp_file: null,
      info_carousel: [
        {
          imgUrl: require("../assets/img/logo-barrilito-express-circle.webp"),
          alt: "Logo de Barrilito Express",
          cita: "En Barrilito, estamos muy felices de tener a Quick eat! como aliado en nuestro crecimiento. Nos hizo la vida más fácil para el desarrollo de delivery ¡A nuestros clientes les encanta usar el app y web!",
          autor: "Andrea Tejeda y Ricardo Izaguirre",
          cargo: "Fundadores de",
          negocio: "Barrilito",
        },
        {
          imgUrl: require("../assets/img/logo-caporal-circle.webp"),
          alt: "Logo de Caporal",
          cita: "Mi experiencia con la plataforma fue excelente. Hemos logrado llegar a más clientes. Tenemos incremento en los ingresos de nuestra empresa. La app es una manera más fácil y rápida de hacer los pedidos.",
          autor: "Eduin Coronel",
          cargo: "Administración en",
          negocio: "Caporal",
        },
        {
          imgUrl: require("../assets/img/logo-man-man-chi-circle.webp"),
          alt: "Logo de Chifa Man Man Chip",
          cita: "Quick Eat automatizó el proceso de atención al cliente y nos permitió dedicarnos exclusivamente a cocinar y deleitar a nuestros clientes.",
          autor: "Andree García",
          cargo: "Fundador de",
          negocio: "Chifa Man Man Chi",
        },

        {
          imgUrl: require("../assets/img/logo-amkha-circle.webp"),
          alt: "Logo de Amkha",
          cita: "El sistema Quick eat no solo brindó una mejor experiencia a nuestros clientes, sino que también redujo labores operativas de nuestro personal en tienda.",
          autor: "Iván Rujel",
          cargo: "Fundador de",
          negocio: "Amkha",
        },

        {
          imgUrl: require("../assets/img/logo-la-revancha-circle.webp"),
          alt: "Logo de La Revancha",
          cita: "Con Quick eat!, hemos podido perfeccionar y ser más eficientes en el manejo de delivery de nuestro restaurante, reduciendo tiempos de entrega y con una mejor experiencia hacia el cliente.",
          autor: "Rosanyela y José Carlos",
          cargo: "Fundadores de",
          negocio: "La Revancha",
        },
      ],
    };
  },
  setup() {
    const route = useRoute();
    const canonicalUrl = `https://www.quickeat.pe${route.path}`;
    useHead({
      title: "Quick eat!",
      link: [{ rel: "canonical", href: canonicalUrl }],
      meta: [
        // Metadatos Html
        {
          name: "description",
          content:
            "La solución definitiva para los restaurantes. Vende, fideliza, controla y sé el mejor con Quick eat!.",
        },
        {
          name: "keywords",
          content:
            "Quickeat, Quick eat, Quick eat!, delivery, take out, recojo, protocolo, express, fast food",
        },
        { name: "author", content: "Quick eat" },
        { name: "copyright", content: "2024 - Quick eat" },

        // Metadatos Google
        { name: "robots", content: "index, follow" },
        { name: "googlebot", content: "index, follow" },
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },

        // Metadatos Open Graph (https://ogp.me/)
        { name: "og:title", content: "Software todo en uno para restaurantes" },
        { name: "og:description", content: "Ecosistema digital para restaurantes que incluye las ventas en línea, CRM y ERP." },
        { name: "og:image", content: "../assets/img/open-graph/quick-eat.png" },
        { name: "og:url", content: "https://quickeat.pe/" },
        { name: "og:type", content: "website" },
        { name: "og:site_name", content: "Quick eat" },

        // // Metadatos Twiter Card (https://developer.x.com/en/docs/twitter-for-websites/cards/guides/getting-started)
/*        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: "Software todo en uno para restaurantes" },
        { name: "twitter:description", content: "Ecosistema digital para restaurantes que incluye las ventas en línea, CRM y ERP." },
        { name: "twitter:image", content: "../assets/img/open-graph/quick-eat.png" },
        { property: "twitter:url", content: "https://quickeat.pe/" },
        { name: "twitter:site", content: "@nombredeusuariotwiter" },
        { name: "twitter:creator", content: "@nombredeusuariotwiter" },*/
      ],
    });
  },
  mounted() {
    this.typeWriter();
  },
  beforeUnmount() {
    if (this.bannerIntervalId !== null) {
      clearInterval(this.bannerIntervalId);
    }
  },
  methods: {
    // async previewFiles (event) {
    //   console.log(event.target.files)
    //   this.temp_file = event.target.files[0]
    //   console.log('el archivo es', this.temp_file)
    //   await this.viewImage()
    // },
    // async viewImage() {
    //   const file = this.temp_file;
    //   console.log(file);
    //   console.log("el type", file.type)
    //   if (file.type !== 'application/pdf') {
    //     const res = await imageConversion.compressAccurately(file,{
    //       size: 150,    //The compressed image size is 100kb
    //       type: "image/jpeg",
    //     })
    //     console.log(res);
    //     this.temp_file = res
    //   }
    //   // var file2 = new File([res], this.login.name + ' ' + this.login.subject);
    // },

    typeWriter() {
      const currentPhrase = this.phrases[this.currentPhraseIndex];
      if (this.isDeleting) {
        if (this.index > 0) {
          this.displayedText = currentPhrase.substring(0, this.index - 1);
          this.index--;
          setTimeout(this.typeWriter, 50); // Velocidad de borrado
        } else {
          this.isDeleting = false;
          this.currentPhraseIndex =
            (this.currentPhraseIndex + 1) % this.phrases.length;
          setTimeout(this.typeWriter, 500); // Pausa antes de escribir la siguiente frase
        }
      } else {
        if (this.index < currentPhrase.length) {
          this.displayedText = currentPhrase.substring(0, this.index + 1);
          this.index++;
          setTimeout(this.typeWriter, 100); // Velocidad de escritura
        } else {
          setTimeout(() => {
            this.isDeleting = true;
            this.typeWriter();
          }, 1000); // Pausa antes de empezar a borrar
        }
      }
    },

    toggleClick(item) {
      this.selectedToggle = this.selectedToggle === item ? null : item;
    },

    openLink(name) {
      this.$gtag.event(name, { click_desde: "home" });
      switch (name) {
        case "services":
          document.getElementById("services").scrollTop =
            document.getElementById("services").scrollHeight;
          window.scrollTo(
            0,
            document.getElementById("services").offsetTop - 64
          );
          window.fbq("track", "Lead", { currency: "PEN", value: 9.99 });
          break;
        case "larevancha":
          window.open("https://www.larevancha.pe", "_blank");
          break;
        case "barrilito":
          window.open("https://www.barrilitoexpress.com", "_blank");
          break;
        case "kingkronuts":
          window.open("https://www.kingkronuts.com", "_blank");
          break;
        case "dondewalter":
          window.open("https://www.dondewalter.pe", "_blank");
          break;
        case "amkhaperu":
          window.open("https://www.amkha.pe", "_blank");
          break;
        case "adiccionburger":
          window.open("https://www.adiccionburger.com", "_blank");
          break;
        case "caporal":
          window.open("https://www.caporal.pe", "_blank");
          break;
      }
    },
    async sendForm() {
      this.$gtag.event("conversion", {
        send_to: "AW-10975127378/UzlACIr8k9wDENLOrPEo",
      });
      console.log("info", this.user_test);

      if (this.user_test.name.length <= 3) {
        this.$toast.error("Debe ingresar su nombre completo");
        this.$gtag.event("formulario_incompleto", { fail: "nombre_persona" });
        return;
      }
      if (this.user_test.business_name.length < 3) {
        this.$toast.error("Debe ingresar el nombre comercial de su negocio");

        this.$gtag.event("formulario_incompleto", { fail: "nombre_comercial" });
        return;
      }
      if (this.user_test.tax_id.length !== 11) {
        this.$toast.error("Debe ingresar un RUC correcto");
        this.$gtag.event("formulario_incompleto", { fail: "ruc" });
        return;
      }
      if (this.user_test.phone.length < 9) {
        this.$toast.error("Debe ingresar un número telefónico válido");
        this.$gtag.event("formulario_incompleto", { fail: "phone" });
        return;
      }
      if (this.user_test.email.length <= 4) {
        this.$toast.error("Debe ingresar un correo válido");
        this.$gtag.event("formulario_incompleto", { fail: "error_correo" });
        return;
      }
      await this.sendMailServ();
    },
    async sendMailServ() {
      try {
        this.loading_send = true;
        this.user_test.file = new FormData();
        if (this.temp_file != null) {
          this.user_test.file.append("file", this.temp_file);
        }
        this.user_test.file.append("name", this.user_test.name);
        this.user_test.file.append(
          "business_name",
          this.user_test.business_name
        );
        this.user_test.file.append("tax_id", this.user_test.tax_id);
        this.user_test.file.append("phone", this.user_test.phone);
        this.user_test.file.append("email", this.user_test.email);

        let response = await User.submitForm(this.user_test.file);
        this.$gtag.event("formulario_enviado", { success: "enviado_servidor" });
        if (response.data.success) {
          this.$toast.success("Información registrada con éxito");

          this.loading_send = false;
          this.user_test.name = "";
          this.user_test.email = "";
          this.user_test.business_name = "";
          this.user_test.tax_id = "";
          this.user_test.phone = "";
          this.user_test.file = null;
        } else {
          this.loading_send = false;
          console.log("error", response.message);
          this.$bvToast.toast(response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      } catch (error) {
        console.log("el error es", error);
        this.$toast.error(error);
        this.loading_send = false;
      }
    },
  },
  computed: {
    send_text() {
      if (this.loading_send) {
        return "Cargando...";
      } else {
        return "Solicitar información";
      }
    },
  },
};
</script>

<style scoped>
blockquote {
  margin: 0;
}

cite {
  font-style: normal;
}

img {
  max-width: 100%;
}

form {
  display: grid;
  gap: 1rem;
}

/* REPEAT CLASS */
.section {
  padding: 3.125rem 1rem;
  text-align: center;
}
.initial_section {
  padding: 0;
  text-align: center;
}

.grid {
  display: grid;
  grid-gap: 1.25rem;
}

.hero {
  background-color: var(--complementary);
  padding: 25px 0;
  color: var(--text-white);
}

.hero__information {
  text-align: center;
}

.hero__container-h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  margin-bottom: 20px;
}

.hero h1 {
  animation: blink 0.7s step-end infinite;
  text-wrap: inherit;
  line-height: 2.625rem;
}

@keyframes blink {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

.hero p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.hero hr {
  margin: 1.25rem 0;
  color: var(--text-white);
}

.hero__services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.hero__services p {
  display: flex;
  align-items: center;
  gap: 8px;
}

.hero__services svg {
  min-width: 24px;
  min-height: 24px;
}

.hero__image {
  position: relative;
}

.hero__image img:first-child {
  width: clamp(240px, 100%, 300px);
  aspect-ratio: 1/ 1.12;
  margin-inline: auto;
}

.hero__image-absolute {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 20px;
  aspect-ratio: 1 / 0.5;
  width: clamp(200px, 100%, 400px);
}

.compromiso__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 25px 0;
}

.compromiso__information {
  text-align: center;
}

.compromiso__information h4 {
  border-radius: 8px;
  padding: 6px;
  background-color: var(--primary-50);
  color: var(--primary-600);
  font-weight: var(--semibold);
  width: fit-content;
  margin-inline: auto;
}

.compromiso__information strong {
  color: var(--primary-600);
  margin: 16px 0;
}

.compromiso__information h2 {
  margin: 12px 0;
}

.compromiso__information p:last-child {
  margin-top: 16px;
}

.compromiso img {
  aspect-ratio: 1;
  width: clamp(240px, 100%, 300px);
  margin-inline: auto;
}

.competidores {
  padding: 25px 0;
  background-color: var(--primary-50);
}

.competidores h2 {
  margin-bottom: 16px;
  text-align: center;
}

.competidores__services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.competidores__services-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background-color: var(--text-white);
  padding: 16px;
  text-align: center;
  width: 150px;
}

.competidores__services-item h3 {
  font-size: 16px;
}

.competidores__services-item img {
  aspect-ratio: 1;
  width: 48px;
}

.quickeat-services {
  background-color: #f8f9fa;
  padding: 25px 0;
}

.quickeat-services-title {
  margin-bottom: 16px;
}

.quickeat-services-title > * {
  text-align: center;
}

.subsubtitle {
  border-radius: 8px;
  padding: 6px;
  background-color: var(--primary-50);
  color: var(--primary-600);
  font-weight: var(--semibold);
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 10px;
  margin-inline: auto;
}

.quickeat-services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.integration {
  padding: 25px 0;
  background-color: var(--complementary);
}

.integration__titles > * {
  text-align: center;
  color: var(--text-white);
}

.integration__titles p {
  margin: 16px 0;
}

.integration__list {
  display: grid;
  grid-template-columns: repeat(2, 150px);
  gap: 20px;
  justify-content: center;
}

.integration__list article {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 150px;
  min-height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
}

.integration__list :is(h4, .img) {
  z-index: 5;
}

.integration__list h4 {
  width: 115px;
  text-align: center;
  font-size: 0.875rem;
  font-weight: var(--semibold);
}

.integration__list .img {
  aspect-ratio: 1;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
}

.integration__list article {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
}

.integration__list article:first-child {
  background-image: url("../assets/img/puzzle-1.webp");
  z-index: 30;
}

.integration__list article:nth-child(2) {
  background-image: url("../assets/img/puzzle-2.webp");
}

.integration__list article:nth-child(3) {
  background-image: url("../assets/img/puzzle-3.webp");
}

.integration__list article:nth-child(4) {
  background-image: url("../assets/img/puzzle-4.webp");
}

.integration__list article:nth-child(5) {
  background-image: url("../assets/img/puzzle-5.webp");
}

.integration__list article:nth-child(6) {
  background-image: url("../assets/img/puzzle-6.webp");
}

.integration__list article::before,
.integration__list article::after {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
}

.integration__list article:first-child::before {
  bottom: 55px;
  right: -30px;
  rotate: 180deg;
  background-image: url("../assets/img/puzze-circle-1.webp");
}

.integration__list article:nth-child(2)::after {
  bottom: -20px;
  right: 52px;
  rotate: 280deg;
  background-image: url("../assets/img/puzze-circle-1.webp");
}

.integration__list article:nth-child(3)::before {
  top: -20px;
  right: 50px;
  rotate: 90deg;
  background-image: url("../assets/img/puzze-circle-1.webp");
}

.integration__list article:nth-child(3)::after {
  bottom: -20px;
  right: 50px;
  rotate: 270deg;
  background-image: url("../assets/img/puzze-circle-1.webp");
}

.integration__list article:nth-child(4)::before {
  top: 55px;
  left: -30px;
  background-image: url("../assets/img/puzze-circle-1.webp");
}

.integration__list article:nth-child(5)::after {
  bottom: 55px;
  right: -25px;
  rotate: 180deg;
  background-image: url("../assets/img/puzze-circle-1.webp");
}

.integration__list article:nth-child(6)::before {
  top: -20px;
  right: 50px;
  rotate: 90deg;
  background-image: url("../assets/img/puzze-circle-1.webp");
}

.toknow {
  background-color: var(--primary-50);
  padding: 25px 0;
}

.toknow h2 {
  text-align: center;
}

.toknow strong {
  color: var(--primary-600);
}

.toknow__information {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
}

.toknow__information p {
  margin-bottom: 16px;
}

.toknow__information button {
  display: none;
}

.toknow__video button {
  margin-top: 16px;
  margin-inline: auto;
}

.frame__video {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  border: solid;
  border-width: 2px;
  border-radius: 5px;
  border-color: var(--primary);
}

.services {
  padding: 25px 0;
}

.services h2 {
  text-align: center;
  margin-bottom: 16px;
}

.services b {
  color: var(--primary-600);
  font-weight: var(--semibold);
}

.services__information {
  display: flex;
  flex-direction: column-reverse;
}

.services img {
  aspect-ratio: 1 / 0.7;
  margin: 16px 0;
  width: clamp(200px, 100%, 325px);
  margin-inline: auto;
}

.services__toggle-container {
  margin-top: 16px;
}

.services__toggle-item > p {
  max-height: 0;
  overflow: hidden;
  font-size: 14px;
  transition: max-height 0.3s ease;
}

.services__toggle-item .toggle-click {
  max-height: 400px;
  transition: max-height 1s ease;
  padding: 10px;
}

.tooggle-rotate {
  transform: rotate(180deg);
}

.services__toggle {
  display: flex;
  gap: 8px;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
  padding: 8px;
}

.services__toggle svg {
  min-width: 24px;
  min-height: 24px;
}

.services__toggle p {
  font-weight: var(--semibold);
}

.services__toggle svg:last-child {
  margin-left: auto;
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.customers {
  padding: 25px 0;
}

.customers h2 {
  text-align: center;
}

.customers b {
  color: var(--primary-600);
  font-family: var(--semibold);
}

.customers-list {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
  margin-top: 16px;
}

.customers-list a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 90%;
  position: absolute;
  left: max(calc(200px * 9), 100%);
  animation-name: scroll;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  padding: 16px;
  border-radius: 16px;
  box-shadow: var(--box-shadow);
}

.customers-list img {
  aspect-ratio: 16 / 8;
  object-fit: contain;
}

.customers-list a:nth-child(1) {
  animation-delay: calc(30s / 8 * (8 - 1) * -1);
}

.customers-list a:nth-child(2) {
  animation-delay: calc(30s / 8 * (8 - 2) * -1);
}

.customers-list a:nth-child(3) {
  animation-delay: calc(30s / 8 * (8 - 3) * -1);
}

.customers-list a:nth-child(4) {
  animation-delay: calc(30s / 8 * (8 - 4) * -1);
}

.customers-list a:nth-child(5) {
  animation-delay: calc(30s / 8 * (8 - 5) * -1);
}

.customers-list a:nth-child(6) {
  animation-delay: calc(30s / 8 * (8 - 6) * -1);
}

.customers-list a:nth-child(7) {
  animation-delay: calc(30s / 8 * (8 - 7) * -1);
}

.customers-list a:nth-child(8) {
  animation-delay: calc(30s / 8 * (8 - 8) * -1);
}

@keyframes scroll {
  to {
    left: -200px;
  }
}

.resumen {
  position: relative;
  background-color: var(--primary-600);
  padding: 25px 0;
  color: var(--text-white);
  overflow: hidden;
}

.resumen > div {
  display: flex;
  align-items: center;
  gap: 15px;
}

.resumen h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  line-height: 1.75rem;
}

.resumen img:first-child {
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
}

.resumen p {
  font-size: 1rem;
}

.resumen-vector {
  position: absolute;
  width: 100px;
}

.vector-1 {
  top: -50px;
  right: -20px;
}

.vector-2 {
  left: -20px;
  bottom: -50px;
}

.testimonios {
  padding: 25px 0;
}

.testimonios h2 {
  text-align: center;
}

.contact {
  background-color: var(--complementary-100);
}

.contact__information {
  padding: 25px 16px;
}

.contact__titles {
  margin-bottom: 16px;
}

.contact__titles h2 {
  text-align: center;
  margin-bottom: 10px;
}

.contact button {
  width: fit-content;
  margin-inline: auto;
}

.contact__titles b {
  font-weight: var(--semibold);
  color: var(--primary-600);
}

.contact__spam {
  margin-top: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
}

::placeholder {
  color: #212121;
}

@media only screen and (min-width: 768px) {
  .hero__container {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .hero__container-h1 {
    justify-content: flex-start;
    height: 100px;
  }

  .hero__information {
    text-align: left;
    width: 70%;
  }

  .hero__services {
    justify-content: flex-start;
  }

  .hero hr {
    width: 85%;
  }

  .hero__image {
    width: 40%;
  }

  .compromiso__container {
    flex-direction: row-reverse;
    align-items: center;
  }

  .competidores__services-item h3 {
    line-height: 1.25rem;
  }

  .compromiso__information h4 {
    margin-right: auto;
    margin-left: 0;
  }

  .compromiso__information {
    text-align: left;
  }

  .integration__list {
    grid-template-columns: repeat(3, 200px);
    gap: 30px;
  }

  .integration__list article {
    width: 200px;
    min-height: 200px;
  }

  .integration__list h4 {
    font-size: 1rem;
  }

  .integration__list .img {
    width: 68px;
    height: 68px;
  }

  .integration__list h4 {
    width: 130px;
  }

  .integration__list article::before,
  .integration__list article::after {
    width: 70px;
    height: 60px;
  }

  .integration__list article:first-child,
  .integration__list article:nth-child(4),
  .integration__list article:nth-child(5) {
    flex-direction: column-reverse;
  }

  .integration__list article:first-child {
    background-image: url("../assets/img/puzzle-4-desktop.webp");
  }

  .integration__list article:nth-child(2) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    background-image: url("../assets/img/puzzle-1.webp");
  }

  .integration__list article:nth-child(3) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    background-image: url("../assets/img/puzzle-2-desktop.webp");
  }

  .integration__list article:nth-child(4) {
    grid-column: 3 / -1;
    grid-row: 2 / 3;
    background-image: url("../assets/img/puzzle-6-desktop.webp");
  }

  .integration__list article:nth-child(5) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    background-image: url("../assets/img/puzzle-5-desktop.webp");
  }

  .integration__list article:nth-child(6) {
    grid-column: 3 / -1;
    grid-row: 1 / 2;
    background-image: url("../assets/img/puzzle-3-desktop.webp");
  }

  .integration__list article:first-child::before {
    top: -30px;
    left: 40%;
    rotate: 0deg;
  }

  .integration__list article:nth-child(2)::after {
    top: 40%;
    right: -30px;
    rotate: 90deg;
  }

  .integration__list article:nth-child(3)::after {
    right: 32%;
    bottom: -30px;
  }

  .integration__list article:nth-child(3)::before {
    background-image: url("");
  }

  .integration__list article:nth-child(4)::before {
    top: -30px;
    left: 40%;
    rotate: 0deg;
  }

  .integration__list article:nth-child(5)::before {
    bottom: 30%;
    left: -30px;
    background-image: url("../assets/img/puzze-circle-1.webp");
    rotate: 90deg;
  }

  .integration__list article:nth-child(5)::after {
    bottom: 28%;
    right: -30px;
  }

  .integration__list article:nth-child(6)::before {
    top: 40%;
    left: -30px;
    rotate: 90deg;
  }

  .toknow h2 {
    text-align: left;
  }

  .toknow__inf-video {
    display: flex;
    flex-direction: row-reverse;
    gap: 30px;
    margin-top: 20px;
  }

  .toknow__video {
    width: 100%;
  }

  .toknow__video button {
    display: none;
  }

  .toknow__information button {
    display: block;
  }

  .services__information {
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }

  .resumen > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .resumen img:first-child {
    display: block;
    width: clamp(200px, 100%, 225px);
    height: initial;
  }

  .resumen__information {
    width: clamp(200px, 100%, 500px);
    gap: 30px;
  }

  .resumen__information h2 {
    font-size: var(--h2-tablet);
    line-height: 2.25rem;
  }

  .resumen__information p {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .contact__container {
    display: flex;
    gap: 30px;
  }

  .contact__information {
    width: 50%;
    padding: 25px 0 25px 16px;
  }

  .contact__information h2 {
    text-align: left;
  }

  .contact__image {
    width: 50%;
    background-image: url("../assets/img/formulario-image.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media only screen and (min-width: 1024px) {
  .hero,
  .compromiso,
  .competidores,
  .quickeat-services,
  .integration,
  .toknow,
  .services,
  .customers,
  .resumen,
  .testimonios {
    padding: 50px 0;
  }

  .hero__container {
    gap: 30px;
  }

  .hero__container-h1 {
    height: 130px;
  }

  .hero h1 {
    line-height: 3.5rem;
  }

  .hero__image {
    width: 40%;
  }

  .hero__image img:first-child {
    width: clamp(240px, 100%, 350px);
  }

  .hero__information > p {
    font-size: 1.5rem;
    margin: 20px 0 40px 0;
    line-height: 1.85rem;
  }

  .hero hr {
    width: 100%;
    margin: 0 0 30px 0;
  }

  .hero__services p {
    font-size: 1.25rem;
  }

  .compromiso img {
    width: clamp(240px, 100%, 375px);
  }

  .competidores h2,
  .quickeat-services-title,
  .integration__titles,
  .toknow h2,
  .services h2,
  .customers h2,
  .testimonios h2 {
    margin-bottom: 30px;
  }

  .competidores__services {
    gap: 40px;
  }

  .competidores__services-item {
    width: 170px;
  }

  .competidores__services-item img {
    width: 64px;
  }

  .integration__list {
    grid-template-columns: repeat(3, 250px);
  }

  .integration__list article {
    width: 250px;
    min-height: 250px;
  }

  .integration__list article::before,
  .integration__list article::after {
    width: 80px;
    height: 70px;
  }

  .integration__list article:first-child::before {
    top: -40px;
    left: 34%;
    rotate: 90deg;
  }

  .integration__list article:nth-child(2)::after {
    top: 37%;
    right: -38px;
    rotate: 180deg;
  }

  .integration__list article:nth-child(3)::after {
    right: 34%;
    bottom: -34px;
  }

  .integration__list article:nth-child(4)::before {
    top: -34px;
    left: 35%;
    rotate: 90deg;
  }

  .integration__list article:nth-child(5)::before {
    bottom: 35%;
    left: -38px;
    rotate: 0deg;
  }

  .integration__list article:nth-child(5)::after {
    bottom: 35%;
    right: -40px;
  }

  .integration__list article:nth-child(6)::before {
    top: 36%;
    left: -40px;
    rotate: 0deg;
  }

  .toknow__information p {
    font-size: 1.125rem;
  }

  .services img {
    width: clamp(200px, 100%, 375px);
  }

  .services__toggle-container {
    padding-inline: 16px;
  }

  .resumen-vector {
    width: 200px;
  }

  .contact__information {
    padding: 50px 0 50px 32px;
  }
}

@media only screen and (min-width: 1280px) {
  .hero h1 {
    line-height: 4rem;
  }

  .hero__image {
    width: 70%;
    padding-bottom: 20px;
  }

  .hero__image img:first-child {
    width: clamp(240px, 100%, 500px);
    margin-top: -20px;
    margin-left: auto;
    margin-right: initial;
  }

  .hero__image-absolute {
    width: clamp(200px, 100%, 600px);
  }

  .hero__services {
    justify-content: space-between;
  }

  .hero__services svg {
    min-width: 28px;
    min-height: 28px;
  }

  .hero__services p {
    font-size: 24px;
  }

  .compromiso__container {
    gap: 50px;
  }

  .compromiso img {
    width: clamp(240px, 100%, 450px);
  }

  .competidores__services {
    width: 1080px;
    margin-inline: auto;
  }

  .quickeat-services-list {
    gap: 40px;
  }

  .toknow__information p {
    font-size: 20px;
  }

  .services img {
    width: clamp(200px, 100%, 600px);
  }

  .resumen {
    padding: 100px 0;
  }

  .resumen > div {
    gap: 60px;
  }

  .resumen__information {
    width: clamp(200px, 100%, 600px);
  }

  .resumen__information h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 30px;
  }

  .resumen__information p {
    font-size: 1.75rem;
  }

  .contact__container {
    margin-inline: auto;
    width: clamp(800px, 100%, 1280px);
  }

  .contact__information {
    padding: 100px 0 100px 32px;
  }
}
</style>
